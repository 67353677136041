<template>
  <div class="container-fluid mt--6">
    <div class="row justify-content-md-center mb-xs-3 mb-xl-3">
      <div class="col-lg-12">
        <div class="card card-stats mb-4 mb-xl-0">
          <div class="card-header position-relative">
            <h3 class="mb-0">Two factor authentication</h3>
          </div>
          <div class="card-body p-4">
            <div class="row">
              <div class="col-md-4">
                <qr-code
                  :text="otpurl"
                  :size="150"
                  class="qrcode"
                  v-loading="loading"
                ></qr-code>
              </div>
              <div class="col-md-8">
                <span class="text-xs"
                  >Two-factor authentication Open your authentication app and
                  enter the code for AB-Money.</span
                >
                <el-form
                  label-width="100px"
                  ref="formAuth"
                  :model="formAuth"
                  :rules="formAuthRule"
                  label-position="top"
                >
                  <el-form-item
                    label="Verification 6-digit code"
                    prop="tokencode"
                  >
                    <el-input
                      v-model="formAuth.tokencode"
                      class="form-control-alternative"
                    ></el-input>
                  </el-form-item>
                  <el-button
                    type="primary"
                    v-loading="submiting"
                    :disabled="submiting"
                    @click="submitShostConnect()"
                  >
                    Verify Code
                  </el-button>
                </el-form>
              </div>
            </div>
          </div>
          <!----><!---->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageApi from "@/api/manage";
import CustomerApi from "@/api/customer";
export default {
  data() {
    return {
      loading: false,
      submiting: false,
      otpurl: "",
      formAuth: {
        tokencode: "",
      },
      formAuthRule: {
        tokencode: [
          {
            required: true,
            message: "Please enter Digi Code",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitShostConnect() {
      this.$refs.formAuth.validate((valid) => {
        if (valid) {
          this.submiting = true;
          ManageApi.postTokenGhostConnect(
            this.$route.params.id,
            this.formAuth
          ).then(({ result, data, message }) => {
              this.submiting = false;
            if (result) {
              this.$store.dispatch('user/loginAuth', data).then(()=>{
                this.getAccount(data.token, () => {
                setTimeout(() => {
                  this.$router.push({ path: "/dashboard" });
                }, 1500);
              });
                
              })
            }else{
                this.$swal("Incorrect code", message, "error");
            }
          });
        }
      });
    },
    getQRCode() {
      this.loading = true;
      ManageApi.getQRCode().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.otpurl = data;
        }
      });
    },
    getAccount(token, cb) {
      CustomerApi.account().then(({ result, data }) => {
        if (result) {
          data["token"] = token;
          this.$store.dispatch("user/login", data).then(() => {
            cb();
          });
        }
      });
    },
  },
  created() {
    this.getQRCode();
  },
};
</script>

<style>
</style>